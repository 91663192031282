<template>
  <div>
    <WorksInProgress ref="wDialog" title="Viewer"/>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand class="d-none d-sm-block" href="#"><img src="../assets/logo.png" class="d-inline-block align-top" alt="Saince" height="30"></b-navbar-brand>
      <b-navbar-nav>
        <b-nav-text class="fixedHeight">&nbsp;</b-nav-text>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button-group>
          <b-button @click="handleClose" variant="secondary" :size="buttonSize" title="Close Report">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
<script>
import WorksInProgress from './WorksInProgress.vue'
import broadcast from '../common/broadcast'

export default {
  name: 'reportWindowNavBar',
  components: {
    WorksInProgress
  },
  data() {
    return {
      buttonSize: "sm"
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  methods: {
    help() {
      this.$refs.wDialog.show()
    },
    handleClose() {
      var answer = true
      if (this.$store.getters.isReportOpenForEdit(this.$store.state.uid)) {
        answer = confirm('Report open in editor may have unsaved changes. Are you sure you want to close this window?')
      }
      if (answer) {
        this.$log.debug('posting CLOSE_REPORT_WINDOW_MSG')
        this.$store.commit('changeActiveComponent', '')
        broadcast.postMessage(broadcast.CLOSE_REPORT_WINDOW_MSG, this.$store.state.uid)
      }
    },
    handleResize() {
      this.buttonSize = (window.innerWidth < 767) ? "sm" : "md"
    },
  }
}    
</script>
<style scoped>
.fixedHeight {
  height: 36px;
  min-height: 36px;
}
</style>